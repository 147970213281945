<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 m-4">
      <vx-card v-if = "registerSuccess == 1">
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="px-8 pt-8 pb-8">
                <div class="vx-card__title mb-4 text-center">
                  <h1 class="mb-4">{{$t('register.successful')}}</h1>
                </div>
                <div class="pt-5">
                  <div class="flex flex-wrap my-5" style = "justify-content: center">
                    <!-- <vs-checkbox class="mb-3"></vs-checkbox> -->
                    <div>{{$t('register.confirm')}} </div>
                    <router-link to="/pages/login">&nbsp;{{$t('register.logIn')}}</router-link>

                  </div>
                </div>
                <div class="pt-5">
                  <div class="flex flex-wrap my-5" style = "justify-content: center">
                    <!-- <vs-checkbox class="mb-3"></vs-checkbox> -->
                    <div>{{$t('register.notReceive')}}</div>
                    <router-link v-on:click.native="resendEmail" to = "" >&nbsp;{{$t('register.toResend')}}</router-link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card v-if = "registerSuccess == 0">
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="px-8 pt-8 pb-8">
                <div class="vx-card__title mb-4 text-center">
                  <h1 class="mb-4">{{$t('register.anAccount')}}</h1>
                </div>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      v-validate="'required|min:3'"
                      data-vv-validate-on="blur"
                      type="account"
                      name="fullName"
                      :label="$t('register.fullName')"
                      v-model="user.fullName"
                      autocomplete="off"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{
                      errors.first("fullName")
                    }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      v-validate="'required|min:3'"
                      data-vv-validate-on="blur"
                      type="text"
                      name="organizationName"
                      :label="$t('register.organizationName')"
                      v-model="user.organizationName"
                      autocomplete="off"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{
                      errors.first("organizationName")
                    }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      v-validate="'required|email'"
                      data-vv-validate-on="blur"
                      type="email"
                      name="email"
                      label="Email"
                      v-model="user.email"
                      autocomplete="off"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{
                      errors.first("email")
                    }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      v-validate="'numeric|min:10|max:11'"
                      data-vv-validate-on="blur"
                      type="text"
                      name="phoneNumber"
                      :label="$t('register.phoneNumber')"
                      v-model="user.phoneNumber"
                      autocomplete="off"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{
                      errors.first("phoneNumber")
                    }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      v-validate="{required: true}"
                      data-vv-validate-on="blur"
                      type="password"
                      name="password"
                      :label="$t('register.password')"
                      v-model="user.password"
                      autocomplete="off"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{
                      errors.first("password")
                    }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      v-validate="{required: true, confirmed:user.password}"
                      data-vv-validate-on="blur"
                      type="password"
                      name="rePassword"
                      :label="$t('register.rePassword')"
                      v-model="user.rePassword"
                      autocomplete="off"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{
                      errors.first("rePassword")
                    }}</span>
                  </div>
                </div>
                <!-- <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">Giới tính</label>
                    <v-select
                      class=""
                      v-model="genderModel"
                      :clearable="false"
                      :options="genderOptions"
                      data-vv-validate-on="input"
                      name="gender"/>
                    <span class="text-danger text-sm">{{
                      errors.first("gender")
                    }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">Ngày sinh</label>
                    <datepicker :format="'dd/MM/yyyy'" v-model="user.birthday"
                                data-vv-validate-on="input" name="birthday"></datepicker>
                    <span class="text-danger text-sm">{{ errors.first('birthday') }}</span>
                  </div>
                </div> -->
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{$t('register.city')}}</label>
                    <v-select
                      height="140px"
                      :dense="true"
                      v-model="province"
                      :clearable="false"
                      :options="cityOptions"
                      data-vv-validate-on="input"
                      name="city"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("city")
                    }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{$t('register.district')}}</label>
                    <v-select
                      class=""
                      v-model="district"
                      :clearable="false"
                      :options="districtOptions"
                      data-vv-validate-on="blur"
                      name="district">
                      <span slot="no-options">
                        {{$t('register.notvalue')}}
                      </span>
                      </v-select>
                    <span class="text-danger text-sm">{{
                      errors.first("district")
                    }}</span>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="vs-input--label">{{$t('register.commune')}}</label>
                    <v-select
                      class=""
                      v-model="subDistrict"
                      :clearable="false"
                      :options="subDistrictOptions"
                      data-vv-validate-on="blur"
                      name="subDistrict"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("subDistrict")
                    }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <vs-input
                      class="w-full"
                      :label="$t('register.houseNumber')"
                      v-model="user.houseNumber"
                      name="houseNumber"/>
                    <span class="text-danger text-sm">{{
                      errors.first("houseNumber")
                    }}</span>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full mt-2">
                    <vs-input
                      class="w-full"
                      :label="$t('register.address')"
                      v-model="fullAddressModel"
                      name="full_address"
                      readonly="true"/>
                  </div>
                </div>

                <div class="pt-5">
                  <div class="flex flex-wrap my-5">
                    <!-- <vs-checkbox class="mb-3"></vs-checkbox> -->
                    <div>{{$t('register.already')}} </div>
                    <router-link to="/pages/login">&nbsp;{{$t('register.logIn')}}</router-link>

                  </div>
                  <div class="flex flex-wrap justify-between mb-3">
                    <vs-button type="border" @click="registerUser">{{$t('register.register')}}</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import province from '@/assets/utils/province.js';
  import Datepicker from 'vuejs-datepicker';
  export default {
    data() {
      return {
        registerSuccess: 0,
        genderOptions: [
          {value: 0, label: 'Khác'},
          {value: 1, label: 'Nữ'},
          {value: 2, label: 'Nam'}
        ],
        cityOptions: province.getProvince(),
        districtOptions: province.getDistrict(),
        subDistrictOptions: province.getSubDistrict(),
        user: {
          gender: null,
          city: null,
          district: null,
          subDistrict: null,
          address: null,
          houseNumber: null,
          password: "",
          rePassword: ""
        },
      };
    },
    components: {
      vSelect,
      Datepicker,
    },
    computed: {
      validateForm() {
        return !this.errors.any();
      },
      genderModel: {
        get() {
          let obj = this.genderOptions.find(x => x.value === this.user.gender);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.gender
          }
        },
        set(obj) {
          this.user.gender = obj.value;
        }
      },
      province: {
        get() {
          let obj = this.cityOptions.find(x => x.value === this.user.city);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.city
          }
        },
        set(obj) {
          this.user.city = obj.value;
          this.districtOptions = province.getDistrict(obj.value);
          this.user.district = this.districtOptions[0].value;
          this.subDistrictOptions = province.getSubDistrict(this.user.city, this.user.district);
          this.user.subDistrict = this.subDistrictOptions[0].value;
        }
      },
      district: {
        get() {
          let obj = this.districtOptions.find(x => x.value === this.user.district);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.district
          }
        },
        set(obj) {
          this.user.district = obj.value;
          this.subDistrictOptions = province.getSubDistrict(this.user.city, obj.value);
          this.user.subDistrict = this.subDistrictOptions[0].value;
        }
      },
      subDistrict: {
        get() {
          let obj = this.subDistrictOptions.find(x => x.value === this.user.subDistrict);
          if (!obj) {
            return null;
          }
          return {
            label: obj.label,
            value: this.user.subDistrict
          }
        },
        set(obj) {
          this.user.subDistrict = obj.value
        }
      },
      fullAddressModel: {
        get() {
          let houseNumber = `${this.user.houseNumber ? this.user.houseNumber + ', ' : ''}`
          let subDistrict = `${this.subDistrict && this.subDistrict.label ? this.subDistrict.label + ', ' : ''}`
          let district = `${this.district && this.district.label ? this.district.label + ", " : ''}`
          let province = `${this.province && this.province.label ? this.province.label: ''}`;
          /* eslint-disable */
          this.user.fullAddress = houseNumber + subDistrict + district + province;
          return this.user.fullAddress;
        },
      }
    },
    methods: {
        getLabelString(object) {
            return (object && object.label);
        },
        changeGender () {
            this.$validator.validateAll()
        },
        async registerUser(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            this.$vs.loading();
            this.user.domain = location.protocol + '//' + location.host
            this.$oauth.post('/registration/email', this.user).then((response) => {
                this.registerSuccess = 1;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
                });
            });
        },
        resendEmail () {
            this.$vs.loading();
            this.user.domain = location.protocol + '//' + location.host
            this.$oauth.post('/registration/send-email-active', this.user).then((response) => {
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
                });
            });
        }
    }
  };
</script>
